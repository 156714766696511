/**
 * oho-sticky-header.js
 */
(function($) {
  $(document).ready(function() {
    $header = $("#site-header");
    $body = $("body");

    // Set up our fixed/sticky header.
    $header.addClass("fixed").removeClass("static");

    // Fill the space the header takes up to bump the content below it.
    // Unless we want the content to fill the space under the header.

    var headerFillerHeight = $header.height();

    // TODO: originally had collapse set when breadcrumbs not-present; find workaround/use cases
    // var collapseContentIntoHeader = ($body.hasClass("node-type-story")) ||
    //   ($body.hasClass("node-type-news") && $("#newsTopCombo").length) ||
    //   ($(".breadcrumbs").length === 0);

    var heroActive = $('.hero').length || $('.hero-with-video-region').length;
    var openingLayerPanel = $('.layer-panel:first-child').length;

    var collapseContentIntoHeader = ($body.hasClass("node-type-story")) ||
      ($body.hasClass("node-type-news") && $("#newsTopCombo").length) ||
      ($body.hasClass("node-type-landing-page")) ||
      ($body.hasClass("node-type-school")) ||
      ($body.hasClass("node-type-home-2020")) ||
      ($body.hasClass("node-type-department") && $(".dept2021").length) ||
      ($body.hasClass("node-type-office") && $(".hero").length) ||
      ($body.hasClass("node-type-lp2") && heroActive) ||
      ($body.hasClass("node-type-lp2") && openingLayerPanel) ||
      ($body.hasClass("page-100reasons"));

    var extraHeaderFillerHeight = (collapseContentIntoHeader) ? -2 : 32;

    $("<div/>", {
      "height": headerFillerHeight + extraHeaderFillerHeight,
      "aria-hidden": true,
      "id": "headerBumper",
      "class": "hide-for-print",
    }).insertAfter($header);

    /**
     * StickyHeader function.
     *
     * The scrollDifference check limits hair-trigger scroll action, as with
     * extra-sensitive Magic Mouse.
     *
     * The $stickyHeader should only be visible if the $staticHeader is out of
     * view.
     *
     * We then check if 'data-directional-scroll_reveal' is true; if the User is
     * scrolling down the $stickyHeader is not visible, if the user is Scrolling
     * up the $stickyHeader then becomes visible.
     */
    $.fn.ohoStickyHeader = function() {
      // Sticky Header elements
      var $stickyHeader = $("#site-header.fixed");
      $stickyHeader.addClass('active');

      // Variables for scroll-sensitivity check. Positive scroll difference,
      // scrolling up the page; negative, scrolling down the page. Sensitivity,
      // number of pixels to scroll before triggering header state change.
      var lastWindowTop = $(window).scrollTop();
      var scrollDifference = 0;
      var sensitivity = 4;

      // Variables for the stickyHeaderAction function itself.
      // TODO: can these be combined with the sensitivity check variables?
      var $lastScrollTop = 0;
      var $headerHeight = $(".site-header").outerHeight();
      var $superHeaderHeight = 0;

      var $adminMenuPaddingBump = 0;
      if ($('.admin-menu').length) {
        $adminMenuPaddingBump = ($(window).width() <= 1024) ? 50 : 29;
      }

      var stickyHeaderAction = function() {
        if ($(window).scrollTop() >= 0) {
          var $st = $(this).scrollTop();
          if ($st > $lastScrollTop) {
            // downscroll code
            $stickyHeader.css("top", -$headerHeight + $adminMenuPaddingBump);
          } else {
            // upscroll code
            $stickyHeader.css("top", "0" - $superHeaderHeight + $adminMenuPaddingBump);
          }
          $lastScrollTop = $st;
        }

        return true;
      };

      var stickyHeaderHashAction = function() {
        var hash = window.location.hash;
        $(window).load(function() {
          // if directional scroll is enabled to reveal the header on upscroll
          if ($header.attr("data-directional-scroll_reveal", "true")) {
            //if we are loading the page below the hash upscrolling the whole header is visible
            if ($(window).scrollTop() >= $(hash).offset().top) {
              $('html, body').animate({
                scrollTop: $(hash).offset().top - $("header.static").outerHeight()
              }, 800);
              //if we are loading the page above the hash upscrolling just the breadcrumbs are visible
            } else if ($(window).scrollTop() < $(hash).offset().top) {
              $('html, body').animate({
                scrollTop: $(hash).offset().top - $(".breadcrumb-container").outerHeight()
              }, 800);
            }
          } else {
            if ($(window).scrollTop() > $(hash).offset().top) {
              $('html, body').animate({
                scrollTop: $(hash).offset().top - $("header.static").outerHeight()
              }, 800);
            }
          }
        });
      };

      // If the User is directed to a page with a hash with a sticky header
      if ($("header.fixed") && window.location.hash) {
        stickyHeaderHashAction();
      }

      // Don't flip state on the home page. Prevent an issue where we get
      // white space above the top of the page on scroll. Also, you're on the
      // home page: we want you to know about and use the menu, without fuss.
      if (!$(".front").length) {
        // Created a throttled scroll difference checker. Setting the
        // frequency fairly high to try to prevent an issue where the close
        // icon might disappear after tapping the menu icon.
        var throttledScrollCheck = obieFns.throttle(function() {
          scrollDifference = lastWindowTop - $(window).scrollTop();
          lastWindowTop = $(window).scrollTop();

          if (scrollDifference < sensitivity * -1 || scrollDifference > sensitivity) {
            stickyHeaderAction();
          }
        }, 50);

        $(window).scroll(function(event) {
          throttledScrollCheck();
        });
      }
    };

    /**
     * Activate stickyHeader.
     */
    $(window).ohoStickyHeader();

    /**
     * Prevent body scrolling on mobile devices
     *
     * While you're not hitting the edges of your div's content, you need to
     * allow the native touchmove event to work on that element (so it can
     * scroll), but you're going to want to stop the event from bubbling up
     * the DOM so that it doesn't trigger scrolling on the page body.
     *
     * When you hit the boundary of your element, you need to prevent the
     * native momentum scrolling entirely.
     *
     * where scrollElem is your DOM node
     */

    if ($(".touch").length) {
      var scrollElem = document.getElementById("header-pane");
      scrollElem.addEventListener('touchstart', function(event) {
        this.allowUp = (this.scrollTop > 0);
        this.allowDown = (this.scrollTop < this.scrollHeight - this.clientHeight);
        this.prevTop = null;
        this.prevBot = null;
        this.lastY = event.pageY;
      });

      scrollElem.addEventListener('touchmove', function(event) {
        var up = (event.pageY > this.lastY),
          down = !up;
        this.lastY = event.pageY;

        if ((up && this.allowUp) || (down && this.allowDown)) event.stopPropagation();
        else event.preventDefault();
      });
    }
  });
})(jQuery);
