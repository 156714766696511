/**
 * theme-scripts.js
 */
(function($) {
  $(document).ready(function() {
    // Drupal adds the js class; we need to remove our no-js class.
    $('html').removeClass('no-js');

    /**
     * Apply link
     */
    $('.apply-widget-link')
      .addClass('js-modal')
      .attr('data-modal-focus-id', 'js-modal-content a.btn--arts-science')
      .attr('data-modal-content-id', 'modal-apply');

    /**
     * We have to add an overview link for mobile purposes first z because the
     * top-level link is not directly clickable (click event being used to
     * expand the children)
     */

    $('#main-menu li:has(ul) > a').each(function() {
      var $overview = $(this).clone();
      var $overviewDestinationSelector = $(".menu .menu");
      var $overviewDestination = $(this).closest('li').find($overviewDestinationSelector);
      var overviewLabel = $(this).text() + ' Overview';
      $($overview).text('Overview')
        .attr('aria-label', overviewLabel)
        .prependTo($overviewDestination)
        .wrap('<li class="js-processed"></li>');
    });

    /**
     * Duplicate the CTA menu for mobile purposes.
     */

    const $ctaMenu = $('.site-header__functionality > .cta-menu').clone();
    $ctaMenu.appendTo($('#globalMenuPanel'));
    $ctaMenu.attr('id', 'globalMenuPanelCtaMenu');

    /**
     * Brute force form fix?
     *
     * TODO: move this to a preprocess step?
     */
    $("#edit-field-comment-url-und-0-url").attr('size', '10');

    /**
     * Status remover
     */
    $('.messages.status').click(function(e) {
      $(this).parent().remove();
    })

    /**
     * Scroll-to links
     */

    $("a.skip-to-target").click(function(e) {
      e.preventDefault();
      var pageTarget = $(this).attr('href');
      $('html, body').animate({
        scrollTop: $(pageTarget).offset().top - $(".breadcrumbs").outerHeight()
      }, 800);
    });

    // When the user uses the "skip to main navigation" link, go ahead and open
    // the side menu. This also addresses the issue where Firefox does not
    // correctly set focus on the menu toggle when using the skip link.
    $('#skip-to-nav').on('click', function(e) {
      e.preventDefault();

      if ($('#globalMenuPanel').hasClass('active') === false) {
        $('#globalMenuButton').trigger('click');
        $('#main-menu').find('a').eq(0).focus();
      } else {
        // The menu is already open, so do nothing.
      }

      // Set focus on the first link in the nav.
      $('#main-menu').find('a').eq(0).focus();
    });

    /**
     * Loading indicator on story listing images?
     */
    const $storiesListing = $('.listing--stories-grid');
    if ($storiesListing.length) {
      const storiesListingItems = document.querySelectorAll('.story-listing-item');
      storiesListingItems.forEach(function(item) {
        let loadingImage = new Image();
        loadingImage.addEventListener("load", function() {
          item.classList.add('image-loaded');
        });

        const itemSrc = item.querySelector('img');
        if (itemSrc) {
          loadingImage.src = itemSrc.src;
        }
      })
    }

    /**
     * 2021 Area of Study (College) listing view.
     * Because filters aren't showing up from the view, for some reason?
     */

    const $aosView = $('.view-display-id-college_aos_listing_block');
    if ($aosView.length) {
      function updateTotals() {
        const $aosVisible = $("#aosVisible");
        const $aosTotal = $("#aosTotal");

        let totalAvailable = $(".card.card--term").length;
        let totalVisible = $(".card.card--term[data-offering-filter='true'][data-name-filter='true']").length;

        $aosVisible.text(totalVisible);
        $aosTotal.text(totalAvailable);
      }

      updateTotals();

      // Offering filter
      const $aosViewFilters = $("#aos-offering-filter");
      $aosViewFilters.on('input', function() {
        const curVal = $("input[name='aos-filtering-button']:checked").val();

        if (curVal === 'All') {
          $(".card.card--term").attr("data-offering-filter", "true");
        } else {
          $(".card.card--term").attr("data-offering-filter", "false");
          $(".card.card--term[" + curVal + "]").attr("data-offering-filter", "true");
        }

        updateTotals();
      });

      // Name filter
      const $aosNameFilter = $("#aos-name-filter");
      $aosNameFilter.on("input", function() {
        const curVal = $(this).val().toLowerCase();

        $(".card.card--term").each(function(i, el) {
          const curName = $(el).find('a').text().toLowerCase();

          if (curName.indexOf(curVal) >= 0) {
            $(el).attr("data-name-filter", "true");
          } else {
            $(el).attr("data-name-filter", "false");
          }
        });

        updateTotals();
      })

      // Check URL parameters for pre-filtering purposes
      const offering = obieFns.getUrlParameter('offering');

      if (offering) {
        $("input[name='aos-filtering-button'][value='" + offering + "']").click();
      }
    }
  });
})(jQuery);
